/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
 var Bus = {
  init: function() {
    // initiate Owl Carousel
    Bus.initOwlCarousel();

    // initiate Packery
    Bus.initPackery();

    // init hero video
    Bus.initHeroVideo();

    // init inview (for the header)
    Bus.initInView();

    // binds click on mobile nav
    Bus.initMobileNav();
  },
  initPackery: function() {
    var $container = $('.grid-container');
    var packery = $container.packery({
          columnWidth: $container.find('article.col-md-6')[0],
          percentPosition: true,
          gutter: 0,
          itemSelector: 'article'
        });

    // for safari (temporary?)
    setTimeout(function() {
      packery.packery();
    }, 600);
  },
  initOwlCarousel: function() {
    $(".owl-carousel").owlCarousel({
      nav: true,
      items: 1,
      navText: ['', ''],
      autoHeight: true,
      dotData:true
    });
  },
  initHeroVideo: function() {
    var $heroImage = $('.hero .hero-image'),
        width = $heroImage.css('width'),
        height = $heroImage.css('height'),
        $iframe = $('.hero iframe');

    if ($iframe.length > 0) {
      // set iframe dimensions to image dimensions
      $iframe.css({'width':width, 'height':height});

      $('.play-video').on('click', function(e) {
        var src = $iframe.attr('data-src') + '&autoplay=1';
        $iframe.attr('src', src);
        $iframe.fadeIn();
        $('.overlay').fadeTo(600, 0);
        setTimeout(function() {
          $iframe.css('z-index', 999);
        }, 1000);
        $(this).off('click');
        e.preventDefault();
      });
    }
  },
  initInView: function() {
    var $hero = $('#hero');

    // add small header if scroll is past hero image when first loading the page
    if ($(window).scrollTop() >  $hero.height()) {
      $('.banner').removeClass('large-header');
    }

    // show transparent header if scrolling over the hero image
    $hero.bind('inview', function(event, isInView, visiblePartX, visiblePartY) {
      if (isInView) {
        $('.banner').addClass('large-header');
        // element is now visible in the viewport
        if (visiblePartY == 'top') {
          // top part of element is visible
        } else if (visiblePartY == 'bottom') {
          // bottom part of element is visible
        } else {
          // whole part of element is visible
        }
      } else {
        // element has gone out of viewport
         $('.banner').removeClass('large-header');
      }
    });
  },
  initMobileNav: function() {
    $('.navbar-toggle').on('click', function(e) {
      $('.banner').toggleClass('menu-opened');
      e.preventDefault();
    });
  },
  initScrollTo: function() {
    $('a[href*=#]:not([href=#])').on('click', function(e) {
      var $target = $(this.hash);
      if ($target.length > 0) {
        $('html, body').animate({
          scrollTop: $target.offset().top
        }, 500);
      }
      e.preventDefault();
    });
  },
  gridHover: function() {
    $articles = $('.grid-container article:not(.reason, .fact, .calendar)');

    $articles.hover(function() {
      $(this).find('.entry-summary').addClass('hover');
    }, function() {
      $(this).find('.entry-summary').removeClass('hover');
    });
  }
};

$(window).load(function() {
  Bus.init();
});

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(".banner").headroom();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        Bus.initScrollTo();
        Bus.gridHover();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    // About us page, note the change from about-us to about_us.
    'calendar': {
      init: function() {
        // JavaScript to be fired on the calendar page
        var post_hash =  window.location.hash.substr(1);
        var $post = $('article.calendar[data-scroll-id="' + post_hash + '"]').eq(0);

        if ($post.length > 0) {
          setTimeout(function() {
            $('html body').animate({
              scrollTop: $post.offset().top
            }, 500);
          }, 1000);
        }
      }
    },
    'companies': {
      init: function() {
        // accordion for the companies page
        $('.accordion-btn').on('click', function(e) {
          e.preventDefault();
          var $button = $(this);
          var $button_img = $button.find('img');

          if ( ! $button_img.hasClass('up')) {
            $button.find('img').addClass('up');
          } else {
            $button.find('img').removeClass('up');
          }
          
          $button.prevAll('.entry-content').slideToggle(200);
        });
      }
    },
    'single': {
      init: function() {
        // JavaScript to be fired on the single page
        setTimeout(function() {
          var maxHeight = 0;
          $('.related-articles article').each(function() {
            var height = $(this).find('.image-wrapper').height();

            if (height > maxHeight) {
              maxHeight = height;
            }
          });

          $('.related-articles article .image-wrapper').each(function() {
            $(this).height(maxHeight);
          });
        }, 500);

        // add hover effect on related articles (single post page)
        $('.related-articles a').on({ 
          mouseenter: function() {
            $(this).closest('article').find('.image-hover').css('opacity', '0.4');
          },
          mouseleave: function() {
            $(this).closest('article').find('.image-hover').css('opacity', '0');
          }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
/*
;( function( $, window, document, undefined )
{
    'use strict';
 
    var s = document.body || document.documentElement, s = s.style;
    if( s.webkitFlexWrap === '' || s.msFlexWrap === '' || s.flexWrap === '' ) return true;
 
    var $list       = $( 'article.companies' ),
        $items      = $list.find( '.company-content .row' ),
        setHeights  = function()
        {
            $items.css( 'height', 'auto' );
 
            var perRow = Math.floor( $list.width() / $items.width() );
            if( perRow == null || perRow < 2 ) return true;
 
            for( var i = 0, j = $items.length; i < j; i += perRow )
            {
                var maxHeight   = 0,
                    $row        = $items.slice( i, i + perRow );
 
                $row.each( function()
                {
                    var itemHeight = parseInt( $( this ).outerHeight() );
                    if ( itemHeight > maxHeight ) maxHeight = itemHeight;
                });
                $row.css( 'height', maxHeight );
            }
        };
 
    setHeights();
    $( window ).on( 'resize', setHeights );
    $list.on( 'load', setHeights );
 
})( jQuery, window, document );*/
